'use client';

import React, { useRef } from 'react';

import { mergeRefs } from '@madeinhaus/utils';
import Link from 'next/link';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import ButtonCircle from 'components/ui/ButtonCircle';
import Img from 'components/ui/Img';
import Text from 'components/ui/Text';

import useScrollProgress from 'hooks/useScrollProgress';

import styles from './Products.module.scss';

const MOBILE =
    'https://images.ctfassets.net/j8tkpy1gjhi5/4rbZyjJccGNJ4hneCavIwv/9539dd7cc06de1a841b731f6ddafdd71/Group_Bagel_3Pack.png';

const DESKTOP =
    'https://images.ctfassets.net/j8tkpy1gjhi5/4rbZyjJccGNJ4hneCavIwv/9539dd7cc06de1a841b731f6ddafdd71/Group_Bagel_3Pack.png';

const Hero = ({ productNavigation }) => {
    const titleRef = useRef(null);
    const ctaRef = useRef(null);

    const { setSection, setNode } = useScrollProgress({
        windowHeightBehavior: 'ignore',
        callback: progress => {
            const dist = `${progress * 6}vw`;
            titleRef.current?.style.setProperty(
                'transform',
                `translate3d(0, ${dist}, 0)`
            );
            ctaRef.current?.style.setProperty(
                'transform',
                `translate3d(0, ${dist}, 0)`
            );
        },
    });

    return (
        <div className={styles.hero} ref={setSection}>
            <div className={styles.inner}>
                <div className={styles.headingGroup}>
                    <h1
                        className={styles.title}
                        ref={mergeRefs([setNode, titleRef])}
                    >
                        Delicious runs in the family.
                    </h1>
                    <div className={styles.cta} ref={ctaRef}>
                        <ButtonCTA
                            iconType="location"
                            text={'Where to Buy'}
                            style="filled-cream-white"
                            link={{ url: '/where-to-buy' }}
                        />
                    </div>
                    <div className={styles.heroImage}>
                        <div>
                            <Img
                                className={styles.image}
                                src={MOBILE}
                                fallbackImageWidth={768}
                                alt={'Stack of Tillamook products.'}
                                priority
                                customSources={[
                                    {
                                        breakpoint: 1280,
                                        src: DESKTOP,
                                        imageWidth: 2500,
                                    },
                                    {
                                        breakpoint: 768,
                                        src: DESKTOP,
                                        imageWidth: 1920,
                                    },
                                    {
                                        src: MOBILE,
                                        imageWidth: 1280,
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <nav className={styles.nav} role="navigation">
                {productNavigation.navItemsCollection.items.map((item, i) => {
                    return (
                        <Link
                            key={i}
                            href={`products/${item.slug}`}
                            className={styles.link}
                        >
                            <figure className={styles.aspectBox}>
                                <Img
                                    className={styles.linkImage}
                                    src={item?.thumbnail?.url}
                                    fallbackImageWidth={240}
                                    alt={item.title}
                                    width={item?.thumbnail?.width}
                                    height={item?.thumbnail?.height}
                                    customSources={[
                                        {
                                            breakpoint: 768,
                                            src: item?.thumbnail?.url,
                                            imageWidth: 240,
                                        },
                                        {
                                            src: item?.thumbnail?.url,
                                            imageWidth: 140,
                                        },
                                    ]}
                                    priority
                                />
                            </figure>
                            <Text
                                as="span"
                                className={styles.linkText}
                                baseTheme="bodyMedium"
                                themes={{ medium: 'labelLarge' }}
                            >
                                {item.title}
                            </Text>
                            <ButtonCircle
                                className={styles.linkIcon}
                                as="span"
                                theme="small-fill-creamdark"
                                iconType="chevronRight"
                            />
                        </Link>
                    );
                })}
            </nav>
        </div>
    );
};

Hero.propTypes = {
    productNavigation: PropTypes.shape({
        navItemsCollection: PropTypes.shape({
            items: PropTypes.array.isRequired,
        }),
    }),
};

export default Hero;
