'use client';

import React, { useRef } from 'react';

import { mergeRefs } from '@madeinhaus/utils';
import cx from 'clsx';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';

import useScrollProgress from 'hooks/useScrollProgress';

import styles from './Hero.module.scss';

const IMAGE_MOBILE =
    'https://images.ctfassets.net/j8tkpy1gjhi5/4NnIEtthUafoi0uU8UQeWN/541696c13ac50fb48e8b7faef33baca3/Shred_Farm_8oz_WMMozz_PzaPull_Pack_01.png';

const IMAGE =
    'https://images.ctfassets.net/j8tkpy1gjhi5/4NnIEtthUafoi0uU8UQeWN/541696c13ac50fb48e8b7faef33baca3/Shred_Farm_8oz_WMMozz_PzaPull_Pack_01.png';

const Hero = ({ showIntro }) => {
    const titleRef = useRef(null);
    const buttonRef = useRef(null);

    const { setSection, setNode } = useScrollProgress({
        windowHeightBehavior: 'ignore',
        callback: progress => {
            const dist = `${progress * 6}vw`;
            titleRef.current?.style.setProperty(
                'transform',
                `translate3d(0, ${dist}, 0)`
            );
            buttonRef.current?.style.setProperty(
                'transform',
                `translate3d(0, ${dist}, 0)`
            );
        },
    });

    return (
        <div
            className={cx(styles.root, {
                [styles.showIntro]: showIntro,
            })}
        >
            <div className={styles.hero} ref={setSection}>
                <div className={styles.inner}>
                    <div className={styles.headingGroup}>
                        <h1
                            className={styles.title}
                            ref={mergeRefs([setNode, titleRef])}
                        >
                            EXTRAORDINARY{' '}
                            <span>
                                DAIRY<sup>&trade;</sup>
                            </span>
                        </h1>
                        <ButtonCTA
                            ref={buttonRef}
                            className={styles.cta}
                            text={`maximize your merry`}
                            style="filled-cream-white"
                            link={{
                                url: '/products',
                            }}
                        />
                        <div className={styles.heroImage}>
                            <div>
                                <Img
                                    className={styles.image}
                                    src={IMAGE_MOBILE}
                                    fallbackImageWidth={768}
                                    alt={'Stack of Tillamook products.'}
                                    priority
                                    customSources={[
                                        {
                                            breakpoint: 1280,
                                            src: IMAGE,
                                            imageWidth: 2500,
                                        },
                                        {
                                            breakpoint: 768,
                                            src: IMAGE,
                                            imageWidth: 1920,
                                        },
                                        {
                                            src: IMAGE_MOBILE,
                                            imageWidth: 1280,
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Hero.propTypes = {
    productNavigation: PropTypes.shape({
        navItemsCollection: PropTypes.shape({
            items: PropTypes.array.isRequired,
        }),
    }),
    showIntro: PropTypes.bool,
};

export default Hero;
